<template>
    <div class="">
           <div class="row ">
      <div class="col-12">

        <div class="topSection text-center text-md-left">
          <p class="title">{{ translateCustomText('CHOOSE AN IMAGE') }}</p>
          <p class="description">{{translateCustomText('This image will appear at the top of your shop.')}}</p>
        </div>
      </div>
    </div>
    <div class="scrollable-tbody" style="height: 411px; overflow-y: auto; overflow-x:hidden">
    <div class="row mx-1">
        <div class="col-12 col-md-6">
             <button class="btn text-center  commonBtn manageBtn" @click="openAddImageModal()" >
              <div class="d-flex align-items-center justify-content-center">
                {{translateCustomText('Upload Image')}}
                <svg xmlns="http://www.w3.org/2000/svg" class="mx-2" height="34px" width="34px" fill="currentColor"  viewBox="0 0 122.88 95.52"><path d="M5.68,12.23H61.14a36.79,36.79,0,0,0-2.3,6.7H6.74V88.78h98.72V63.65a37.49,37.49,0,0,0,6.7-2.64V89.88a5.58,5.58,0,0,1-1.65,4,1.6,1.6,0,0,1-.3.26,5.48,5.48,0,0,1-3.73,1.4H5.64a5.61,5.61,0,0,1-4-1.66,5.68,5.68,0,0,1-1.65-4v-72a5.63,5.63,0,0,1,5.64-5.63ZM95.19,0A27.69,27.69,0,1,1,67.51,27.68,27.68,27.68,0,0,1,95.19,0Zm-4,41h8.07a2.91,2.91,0,0,0,2.91-2.9V28.91h5.12a2.4,2.4,0,0,0,2.06-1c1.07-1.61-.39-3.2-1.42-4.33C105,20.44,98.47,14.69,97,13a2.35,2.35,0,0,0-3.7,0C91.86,14.73,85,20.83,82.2,23.92c-1,1.07-2.14,2.54-1.14,4a2.41,2.41,0,0,0,2,1h5.15v9.23A2.91,2.91,0,0,0,91.17,41ZM29,31.78a8.1,8.1,0,1,1-8.09,8.09A8.09,8.09,0,0,1,29,31.78ZM63,66.51,69.68,55a37.3,37.3,0,0,0,19.66,9.61l7.07,18.2H16.16V76.63l6.74-.34,6.74-16.52L33,71.57H43.13L51.9,49,63,66.51Z"/></svg>
              </div>
              <div style="font-size: 14px;">
                {{translateCustomText('Format: JPG, PNG')}}
          </div>
               <div style="font-size: 14px;"> {{translateCustomText('Recommended size: 1000 x 600 px')}}</div>
                <!-- 🔒 -->
             </button>
                   <input
                        type="file"
                       accept=".jpg, .jpeg, .png"
                        style="display: none"
                        ref="UploadNewImageRef"
                       
                        @change="uploadFile"
                      />
        </div>
        <div v-for="image in getImages" :key="image.id" class="col-12 col-md-6" @click="selectedOption=image">
             <LazyImage :src="image.image" :class="[image.id==selectedOption.id?'selected-image':'']" class="commonBtn manageBtn imgEditImage" style=" object-fit: cover;" />
        </div>
      
    </div>
    </div>
      <div class="d-flex mt-3 justify-content-between">
    
  
    <ElthButton   
  @click="backPage()" variant="boardered">
      
     {{translateCustomText('Back')}} 
    </ElthButton>
     <ElthButton  :isLoading="isLoadingSave"  @click="nextPage()">
    {{translateCustomText('Save')}}
    </ElthButton>
       <ElthButton  style="visibility: hidden;" class="d-none d-md-block">
     save
    </ElthButton>
  </div>
    </div>
</template>
<script>
import ElthButton from "./ElthButton.vue";
import TranslationMixin from "../../../customize/mixin/TranslationMixin";
import axios from 'axios'
export default{
    props:{
        images:{
            type:Array
        },
        imagePlan:{
            type:String,
            default:'Free'
        },
        selected_image:{
            type:String,
            default:''
        },
        quizId:Number,
        apiToken:String
    },
      mixins: [TranslationMixin],
      components:{ElthButton},
    data(){
        return{
        selectedOption:{},
        uploadImageFile:null,
        isLoadingSave:false,
        
        
        }
    },
    computed:{
        getImages(){

            return this.images
        }
    },
    methods:{
        translateCustomText(key) {
      return this.translateToItalian(key, this.quizId);
    },
     backPage() {
      this.$emit("moveTo",1);
     
    },
     async nextPage() {
      
    
        this.isLoadingSave=true;
         let data = {
        
         'image':this.selectedOption.image,
          email: localStorage.getItem("already_record_email") || this.$route.query.email,
          api_token: this.apiToken,
          questionId:this.quizId
        };
       if(this.uploadImageFile && this.selectedOption.id=='uploadNewImage')
       {
          const form_data = new FormData()
          data.image = ''
           for (var key in data) {
                form_data.append(key, data[key]);
            }
          form_data.append('image_file', this.uploadImageFile);
          data=form_data;
       }

        await axios.post(
          `/api/custom/collection/update/image`,
          data
        ).then((response)=>{
          if(response.status==200)
          {
            this.toast('Image has been updated.')
              this.$emit('moveTo',1)
          }
          else 
          this.toast('An error has occurred')
        }).catch((error)=>{
          this.toast('An error has occurred');
          throw error})
      this.isLoadingSave=false;
    },
     uploadFile(e) {
        if(this.imagePlan.toLowerCase()=='free')
        {
             this.toast('Update your plan to avail this functionality.')
             return
        }
  
 // Assuming this variable is defined correctly
    const file = e.target.files[0]; // Use 'e' instead of 'event'
    const allowedExtensions = /\.(jpg|jpeg|png)$/i;

      if (!allowedExtensions.test(file.name)) {
        this.toast('Please upload file having extensions .jpg .jpeg .png only.');
        e.target.value = ''; // Clear the file input
        return;
      }
    this.uploadImageFile = e.target.files[0]; 
    if (file) {
        if(this.images[0].id=='uploadNewImage')
            this.images[0].id=-1
      const reader = new FileReader();
      reader.onload = () => {
        let imgUrl = null;
        imgUrl= reader.result; // Access imgUrl inside the onload function
        let addImageOptionImageObj = {
          image: imgUrl,
          id: 'uploadNewImage',
          value: this.translateCustomText("Upload Image"),
          detailValue: 0,
          score_value: 0
        };
        if(this.images[0].id==-1)
        {
            this.images.splice(0, 1, addImageOptionImageObj); 
            // this.images[0] = addImageOptionImageObj; 
        }
        else
         this.images.unshift(addImageOptionImageObj); 

        this.selectedOption=addImageOptionImageObj;
      };
      reader.readAsDataURL(file);
    }
  },
     toast(text){
      this.$toasted.show(this.translateCustomText(text), {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
    },
    openAddImageModal(){
      if(this.imagePlan.toLowerCase()!='free')
      this.$refs.UploadNewImageRef.click()
    else{
       this.toast('Update your plan to avail this functionality.')
    }
    }
    },
    mounted(){
         let optionIndex = this.images.findIndex(x=>x.image==this.selected_image)
         if(optionIndex>-1)
         {
            this.selectedOption=this.images[optionIndex]
         }
         else{
             let addImageOptionImageObj = {
          image: this.selected_image,
          id: 'uploadNewImage',
          value: this.translateCustomText("Upload Image"),
          detailValue: 0,
          score_value: 0
        };
            this.images.unshift(addImageOptionImageObj); 
            this.selectedOption = addImageOptionImageObj
         }
    }
}
</script>
<style scoped>
* {
  --elthThemeColor: #503a3c;
}
.topSection .description{
color: #503A3C;
font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 400;

}
.topSection .title{
  color: #503A3C;
font-family: Montserrat;
font-size: 28px;
font-style: normal;
font-weight: 600;
text-transform: uppercase;
}
.manageBtn {
border-radius: 15px;
background: #FFF;
color:#503A3C;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
margin-top: 20px;
}

.editBtn:hover {
  background: #9c4629 !important;
  /* border: 1px solid ; */
  border-radius: 5px;
  color: #fff;
}
.commonBtn {
height: 187.442px;
width:100%;

text-align: center;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 500;
}
.scrollable-tbody::-webkit-scrollbar {
  width: 22px;
  background:#fff;
}

.scrollable-tbody::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.0);
  border: 1px solid var(--elthThemeColor);
  background-clip: padding-box;
    background:#fff;
}
@media (max-width: 767px) {
 
  .topSection .title{
    font-size: 22px;
    text-align: center;
  }
  .topSection .description{
    text-align: center;
  }
}
.scrollable-tbody::-webkit-scrollbar-thumb {
  background-color: var(--elthThemeColor);
  outline: 1px solid var(--elthThemeColor);
  background-image: linear-gradient(white, white), linear-gradient(white, white),
    linear-gradient(white, white);
  background-position: 50% 38%, 50% 50%, 50% 65%;
  background-repeat: no-repeat;
  background-size: 50% 1px, 50% 1px, 50% 1px;
}

.scrollable-tbody::-webkit-scrollbar-button {
  border: 1px solid var(--elthThemeColor);
  background-size: 10px 10px;
  background-repeat: no-repeat;
  background-position: center center;
  height: 22px;
  width: 1em;
  -webkit-box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.scrollable-tbody::-webkit-scrollbar-button:horizontal:increment {
  background-image: url(https://dl.dropboxusercontent.com/u/55165267/icon2.png);
}

.scrollable-tbody::-webkit-scrollbar-button:end:increment {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQwNC4zMDggNDA0LjMwOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDA0LjMwOCA0MDQuMzA5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTAsMTAxLjA4aDQwNC4zMDhMMjAyLjE1MSwzMDMuMjI5TDAsMTAxLjA4eiIgZmlsbD0iIzAwMDAwMCIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
}

.scrollable-tbody::-webkit-scrollbar-button:start:decrement {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDI1NSAyNTUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI1NSAyNTU7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8ZyBpZD0iYXJyb3ctZHJvcC11cCI+CgkJPHBvbHlnb24gcG9pbnRzPSIwLDE5MS4yNSAxMjcuNSw2My43NSAyNTUsMTkxLjI1ICAgIiBmaWxsPSIjMDAwMDAwIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
}

@media (max-width: 767px) {
  .commonBtn.imgEditImage {
   height: auto !important;
  }
}
.selected-image{
    box-shadow: none;
    outline: 4px solid;
}
</style>